<template>
    <Modal class="full-view" v-model="showForm" title="预览文档" footer-hide fullscreen :mask-closable="false">
        <vue-office-docx v-if="docxShow" :src="docx"/>
        <vue-office-pdf v-if="pdfShow" :src="pdf"/>
        <Spin size="large" fix v-show="loading">
            <Icon type="ios-loading" class="spin-icon-load" size="48"></Icon>
            <div>加载中…</div>
        </Spin>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {downloadFile} from "@/api/system-api";
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
import VueOfficePdf from '@vue-office/pdf'
//引入相关样式
import '@vue-office/docx/lib/index.css'
import {uploadFileURL} from "../../api/system-api";

export default {
    name: "PreViewDocument",
    components: {VueOfficeDocx,VueOfficePdf},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {},
    },
    data() {
        return {
            uploadFileURL: uploadFileURL,
            docxShow: false,
            pdfShow: false,
            title: '',
            docx: '',
            pdf: '',
            loading: false,
            showForm: this.value,
        }
    },
    methods: {
        handlePreView() {
            this.loading = true
            if (this.data.mimeType === '.docx') {
                let para = {
                    type: 'document',
                    fileName: this.data.currentName
                }
                downloadFile(para).then((res) => {
                    let that = this;
                    that.docxShow = true;
                    let fileReader = new FileReader()
                    fileReader.readAsArrayBuffer(res.data)
                    fileReader.onload =  () => {
                        this.docx = fileReader.result
                    }
                    that.loading = false
                }).catch(() => {
                    this.$Message.error('预览失败')
                })
            }else if(this.data.mimeType === '.pdf'){
                let para = {
                    type: 'document',
                    fileName: this.data.currentName
                }
                downloadFile(para).then((res) => {
                    let type = 'application/pdf'
                    const blob = new Blob([res.data], { type })
                    this.pdf = window.URL.createObjectURL(blob)
                    this.pdfShow = true
                    this.loading =false
                }).catch(() => {
                    this.$Message.error('预览失败')
                })
            }
        },
        closeModal(val) {
            this.imgData = null
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.docxShow = false;
                this.pdfShow = false;
                this.docx = '';
                this.pdf = '';
                this.loading = false;
                this.handlePreView()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
