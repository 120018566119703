<template>
    <Modal v-model="showForm" title="新增文档" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="90">
            <FormItem label="文档" prop="fileArray">
                <Upload ref="upload"
                        type="drag"
                        multiple
                        :max-size="50 * 1024"
                        :action="uploadFiles"
                        :default-file-list="addForm.fileArray"
                        :format="['jpg','png','JPG','JPEG','xlsx','xls','doc','docx','pdf','pptx','ppt']"
                        accept=".jpg,.png,.JPG,.JPEG,.xlsx,.xls,.doc,.docx,.pdf,.pptx,.ppt"
                        :data="{type: 'document'}"
                        :before-upload="handleBeforeUpload"
                        :on-exceeded-size="handExceeded"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                        :headers="header">
                    <div class="upload-btn-l">
                        <Icon type="md-cloud-upload"></Icon>
                        <p class="tips">点击上传或拖动文件到这里上传</p>
                    </div>
                </Upload>
            </FormItem>
            <FormItem label="文档简介" prop="memo">
                <Input v-model.trim="addForm.memo" :maxlength="1000" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" placeholder="请输入" />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {uploadFileURL} from "@/api/system-api";
import {reqAddDocument} from "@/api/admin-api";

export default {
    name: "addDocument",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            header: {},
            uploadFiles: uploadFileURL,
            loading: false,
            showForm: this.value,
            addForm: {
                memo: '',
                fileArray: [],
            },
            addFormRule: {
                fileArray: [{required: true, type: 'array', message: '请上传文档', trigger: 'change'}],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }

                this.addForm.files = this.addForm.fileArray.map(item => item.response.data);
                reqAddDocument(this.addForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('添加成功');
                        this.$emit('getDocument');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('添加失败');
                })
            });
        },
        handleBeforeUpload(file) {
            this.addForm.fileArray.push(file)
        },
        //上传文件大小
        handExceeded() {
            this.$Message.error('文件大小不得超过50M')
        },
        handleRemove(file, fileList) {
            this.addForm.fileArray = [...fileList];
        },
        //上传成功
        handleSuccess(res, file) {
            if (res.code === 1) {
                this.$Message.success('上传成功');
            } else {
                this.$Message.error('上传失败');
            }
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
