<template>
    <Modal v-model="showForm" title="查看文档" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="90">
            <FormItem label="图片" v-if="imgData !== null">
                <div class="upload-image-list">
                    <img :src="imgData">
                    <div class="upload-image-list-cover">
                        <Icon type="ios-eye" @click.native="handlePreview(imgData)"></Icon>
                    </div>
                </div>
            </FormItem>
            <FormItem label="文档名称">{{ viewForm.orignalName }}</FormItem>
            <FormItem label="文档类型">{{ viewForm.mimeType }}</FormItem>
            <FormItem label="文档大小">{{ viewForm.contentLength }}</FormItem>
            <FormItem label="文档简介">{{ viewForm.memo }}</FormItem>
            <FormItem label="上传人">{{ viewForm.createByName }}</FormItem>
            <FormItem label="部门">{{ viewForm.deptName }}</FormItem>
            <FormItem label="上传时间">{{ viewForm.createTime }}</FormItem>
            <FormItem label="更新人">{{ viewForm.updatedByName }}</FormItem>
            <FormItem label="更新时间">{{ viewForm.updatedTime }}</FormItem>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imageUrl" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqGetDocumentById} from "@/api/admin-api";
import {downloadFile, reqCodeImg} from "@/api/system-api";

export default {
    name: "viewDocument",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            imageUrl: '',
            showPreview: false,
            imgData: null,
            uploadFileName: [],
            copyFiles: [],
            header: {},
            files: [],
            loading: false,
            showForm: this.value,
            deleteName: [],
            viewForm: {
                currentName: '',
                memo: '',
                mimeType: '',
                orignalName: '',
                contentLength: '',
            },
            viewFormRule: {},
        }
    },
    methods: {
        getDocumentById() {
            reqGetDocumentById({id: this.id}).then((res) => {
                this.viewForm = res.data.data;
                if (this.viewForm.contentLength === 0) {
                    this.viewForm.contentLength = '0B'
                } else {
                    var k = 1024, // or 1024
                        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
                        i = Math.floor(Math.log(this.viewForm.contentLength) / Math.log(k));
                    this.viewForm.contentLength = (this.viewForm.contentLength / Math.pow(k, i)).toPrecision(3) + " " + sizes[i]
                }
                if (this.viewForm.mimeType === '.jpg' || this.viewForm.mimeType === '.png' || this.viewForm.mimeType === '.JPG' || this.viewForm.mimeType === '.JPEG') {
                    this.showImg()
                }
            }).catch(() => {
                this.viewForm = {}
            })
        },
        //展示图片
        showImg() {
            let para = {
                type: 'document',
                fileName: this.viewForm.currentName
            }
            let that = this;
            downloadFile(para).then((res) => {
                that.imgData = window.URL.createObjectURL(new Blob([res.data]));
            }).catch(() => {
                that.imgData = null;
            });
        },
        handlePreview(val) {
            this.imageUrl = val
            this.showPreview = true;
        },
        closeModal(val) {
            this.imgData = null
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.getDocumentById();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
