<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>文档管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.keywords" placeholder="输入文件名" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleBatchDeletion" :disabled="selectedDocument.size === 0"
                            v-if="permissionButton.batchDel">批量删除
                    </Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading"
                       @on-select="handleSelect"
                       @on-select-cancel="handleCancel"
                       @on-select-all="handleSelectAll"
                       @on-select-all-cancel="handleAllCancel">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.document && (row.mimeType === '.docx' || row.mimeType === '.pdf')" @click.native="handlePreView(row, index)">预览</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem v-if="permissionButton.download" @click.native="handleDownload(row, index)">下载</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <add-document v-model="showAddModal" v-on:getDocument="getDocument"></add-document>
        <edit-document v-model="showEditModal" :id="id" v-on:getDocument="getDocument"></edit-document>
        <view-document v-model="showViewModal" :id="id" v-on:getDocument="getDocument"></view-document>
        <pre-view-document v-model="showPreViewModal" :data="data"></pre-view-document>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqDeleteDocument, reqDocumentBatchDel, reqGetDocument} from "@/api/admin-api";
import addDocument from './add'
import editDocument from './edit'
import viewDocument from './view'
import {downloadFile} from "@/api/system-api";
import PreViewDocument from "./preView";

export default {
    name: 'resumeList',
    components: {
        PreViewDocument,
        addDocument,
        editDocument,
        viewDocument
    },
    data() {
        return {
            id: '',
            data: {},
            filter: {
                keywords: '',
            },
            header: {},
            showAddModal: false,
            showEditModal: false,
            showViewModal: false,
            showPreViewModal: false,
            loading: false,
            dataList: [],
            selectedDocument: new Map(),
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                download: false,
                batchDel: false,
                document: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: '文件名',
                    key: 'orignalName',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '文件类型',
                    key: 'mimeType',
                    width: 100,
                },
                {
                    title: '更新人',
                    key: 'updatedByName',
                    width: 120,
                },
                {
                    title: '部门',
                    key: 'deptName',
                    tooltip: 'true',
                    width: 300,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getDocument() {
            this.loading = true;
            this.dataList = [];
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
            };
            reqGetDocument(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.keywords = '';
            let para = {
                pageNum: 1,
                pageSize: 20,
                keywords: this.filter.keywords,
            };
            reqGetDocument(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleDownload(row, index) {
            let para = {
                type: 'document',
                fileName: row.currentName
            }
            downloadFile(para).then((res) => {
                if (res.data.size > 0) {
                    var blob = new Blob([res.data], {
                        type: 'application/vnd.openxmlformats-  officedocument.spreadsheetml.sheet;charset=utf-8',
                    }) //type这里表示xlsx类型
                    var downloadElement = document.createElement('a')
                    var href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = row.orignalName + row.mimeType //下载后文件名
                    document.body.appendChild(downloadElement)
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                } else {
                    this.$Message.error('下载失败')
                }
            }).catch(() => {
                this.$Message.error('下载失败')
            })
        },
        handleAdd() {
            this.showAddModal = true
        },
        handleEdit(row, index) {
            this.id = row.id;
            this.showEditModal = true
        },
        handleView(row, index) {
            this.id = row.id;
            this.showViewModal = true
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除文档',
                content: '确定删除文档：' + row.orignalName + '？此操作不可逆。',
                width: '360',
                onOk: () => {
                    reqDeleteDocument({id: row.id}).then((res) => {
                        if (res.data.code === 1) {
                            this.getDocument();
                            this.$Message.success('删除成功');
                        } else {
                            this.$Message.error('删除失败');
                        }
                    }).catch(() => {

                    })
                }
            })
        },
        handleBatchDeletion() {
            this.$Modal.confirm({
                title: '批量删除',
                content: '确定批量删除所选文档？此操作不可逆。',
                width: '320',
                onOk: () => {
                    let params = []
                    this.selectedDocument.forEach((item) => {
                        params.push(item.id)
                    })
                    reqDocumentBatchDel(params).then((res) => {
                        if (res.data.code === 1) {
                            this.getDocument();
                            this.$Message.success('批量删除成功');
                            this.selectedDocument.clear()
                        } else {
                            this.$Message.error('批量删除失败');
                        }
                    })
                },
            })
        },
        handleSelect(selection, row) {
            this.selectedDocument = new Map(this.selectedDocument.set(row.id, row))
        },
        handleCancel(selection, row) {
            this.selectedDocument.delete(row.id)
            this.selectedDocument = new Map(this.selectedDocument)
        },
        handleSelectAll(selection) {
            selection.forEach((item) => {
                this.selectedDocument.set(item.id, item)
            })
            this.selectedDocument = new Map(this.selectedDocument)
        },
        handleAllCancel(selection) {
            this.dataList.forEach((item) => {
                this.selectedDocument.delete(item.id)
            })
            this.selectedDocument = new Map(this.selectedDocument)
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getDocument();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getDocument();
        },
        handlePreView(row,index){
            this.data = row
            this.showPreViewModal = true
        },
        doSearching() {
            this.pageNum = 1;
            this.getDocument();
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
        this.getDocument()
    }
}
</script>
