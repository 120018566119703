<template>
    <Modal v-model="showForm" title="编辑文档" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="文档名称" prop="orignalName">
                <Input v-model.trim="editForm.orignalName" :maxlength="20" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="文档简介" prop="memo">
                <Input v-model.trim="editForm.memo" :maxlength="1000" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" placeholder="请输入" />
            </FormItem>
            <FormItem class="plain-item" label="文档类型">{{ editForm.mimeType }}</FormItem>
            <FormItem class="plain-item" label="文档大小">{{ length }}</FormItem>
            <FormItem class="plain-item" label="上传时间">{{ editForm.createTime }}</FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {uploadFileURL} from "@/api/system-api";
import {reqEditDocument, reqGetDocumentById} from "@/api/admin-api";

export default {
    name: "editDocument",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            uploadFileName: [],
            copyFiles: [],
            header: {},
            uploadFiles: uploadFileURL,
            files: [],
            loading: false,
            showForm: this.value,
            deleteName: [],
            length : '',
            editForm: {
                currentName: '',
                memo: '',
                mimeType: '',
                orignalName: '',
                contentLength: '',
            },
            editFormRule: {
                orignalName: [
                    {required: true, message: '请输入文档名称', trigger: 'blur'}
                ],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.editForm.deleteName = this.deleteName
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqEditDocument(this.editForm).then((res) => {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success('编辑成功');
                            this.$emit('getDocument');
                            this.closeModal(false);
                        }else {
                            this.$Message.error('编辑失败');
                            this.closeModal(false);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error('编辑失败');
                    });
                }
            });
        },
        getDocumentById() {
            reqGetDocumentById({id: this.id}).then((res) => {
                this.editForm = res.data.data;
                if (this.editForm.contentLength === 0){
                    this.length = '0B'
                }else {
                    var k = 1024, // or 1024
                        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
                        i = Math.floor(Math.log(this.editForm.contentLength) / Math.log(k));
                    this.length = (this.editForm.contentLength / Math.pow(k, i)).toPrecision(3) + " " + sizes[i]
                }
            }).catch(() => {
                this.editForm = {}
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.getDocumentById();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
